var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          height: _vm.tableSize,
          headers: _vm.headers,
          items: _vm.promoItems,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
          search: _vm.search,
          "server-items-length": _vm.serverItemsLength,
          "footer-props": { "items-per-page-options": _vm.pageSizes },
          "items-per-page": _vm.pageSize,
          page: _vm.page,
          "item-key": "id",
          "fixed-header": "",
          dense: ""
        },
        on: {
          "update:items": function($event) {
            _vm.promoItems = $event
          },
          "update:search": function($event) {
            _vm.search = $event
          },
          "update:itemsPerPage": function($event) {
            _vm.pageSize = $event
          },
          "update:items-per-page": function($event) {
            _vm.pageSize = $event
          },
          "update:page": function($event) {
            _vm.page = $event
          },
          "click:row": _vm.viewPromo
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-row",
                  {
                    staticStyle: { cursor: "normal" },
                    attrs: { align: "center" }
                  },
                  [
                    _c(
                      "v-toolbar",
                      { staticClass: "grey lighten-3", attrs: { flat: "" } },
                      [
                        _c("v-toolbar-title", [_vm._v("Promotion Items")]),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "Responsible Party",
                                items: _vm.validParties,
                                "item-text": "name",
                                "item-value": "id",
                                "background-color": "#fff",
                                dense: "",
                                "hide-details": "",
                                outlined: ""
                              },
                              model: {
                                value: _vm.responsiblePartyId,
                                callback: function($$v) {
                                  _vm.responsiblePartyId = $$v
                                },
                                expression: "responsiblePartyId"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-col",
                          {
                            staticStyle: { height: "64px" },
                            attrs: { cols: "4" }
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                placeholder: "Search Promotion Items",
                                "background-color": "#fff",
                                "prepend-inner-icon": "mdi-magnify",
                                "append-icon": "mdi-window-close",
                                autocomplete: "off",
                                "hide-details": "",
                                outlined: "",
                                dense: ""
                              },
                              on: {
                                "click:append": function($event) {
                                  _vm.search = ""
                                }
                              },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-divider")
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }