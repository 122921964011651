import PartyRelationship from '../axios/party-relationship-endpoint'
import { userAccess } from './user-access'

export const getValidParties = {
  data () {
    return {
      validParties: []
    }
  },
  mixins: [userAccess],
  methods: {
    async setValidParties (parties) {
      if (this.isAdmin === true || (parties?.length === 1 && this.$auth.tenant !== 'iprosystems')) {
        return this.validParties = parties
      }
      this.validParties = await this.getValidParties(parties)
    },
    async getValidParties (parties) {
      try {
        const { email } = this.user
        const results = await Promise.allSettled(parties.map(async party => {
          const { id } = party;
          let party1 = {
            party_1_type: "USER",
            party_1_email: email
          }
          let party2 = { 
            party_2_id: id 
          }
          return PartyRelationship.getMatch(party1, party2)
        }))

        const validParties = parties.flatMap((party, index) => {
          const result = results[index]
          if (result.status === 'fulfilled' && result.value.data.related === true) {
            return party
          }
          return []
        })
        return validParties
      } catch (err) {
        console.log(err)
      }
    }
  }
}