import axios from 'axios'

const App = {
  CUSTOMER: process.env.VUE_APP_ES_CUSTOMER_INDEX,
  ITEM: process.env.VUE_APP_ES_ITEM_INDEX,
  PROMO: process.env.VUE_APP_ES_PROMO_INDEX,
  PROMO_ITEM: process.env.VUE_APP_ES_PROMO_ITEM_INDEX,
  PROMO_ITEM_GROUP: process.env.VUE_APP_ES_PROMO_ITEM_GROUP_INDEX
}

class ES {
  constructor () {
    const es = axios.create({
      baseURL: process.env.VUE_APP_ES_ENDPOINT,
      withCredentials: false
    })

    es.interceptors.response.use(this.handleSuccess, this.handleError)
    this.es = es
  }

  handleError (err) {
    return err
  }

  handleSuccess (response) {
    if (Object.keys.hasOwnProperty.call(response, 'data')) {
      response = response.data
    }
    return response
  }

  /**
   * Search ES
   * This resource hits es directly to allow for better searching.
   * To hit ES from the front end we have to utilize aws-exports.js using the
   * Amplify library and 'API' function. Therefore this resource is set up and
   * controlled in a different manner.
   * @param {string} appName - used to get the es index from App enum
   * @param {string} query  - string represent type of query ie/ _search, _msearch, _bulk
   * @param {object} params - JSON Object that represents search requirements as specified by ES
   * @return - search results -> individual results have a root of hits.hits._source
  **/
  post (appName, query, params) {
    const idx = App[appName]
    if (Object.keys.hasOwnProperty.call(params, 'body')) {
      params = params.body
    }
    return this.es({
      url: idx + query,
      method: 'POST',
      data: params
    })
  }

  get (appName, query) {
    const idx = App[appName]
    return this.es({
      url: idx + query,
      method: 'GET'
    })
  }
}

export default new ES()
