<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-data-table
      style="cursor: pointer"
      :height="tableSize"
      :headers="headers"
      :items.sync="promoItems"
      :loading="loading"
      loading-text="Loading... Please wait"
      :search.sync="search"
      :server-items-length="serverItemsLength"
      :footer-props="{'items-per-page-options': pageSizes}"
      :items-per-page.sync="pageSize"
      :page.sync="page"
      item-key="id"
      fixed-header
      dense
      @click:row="viewPromo">
      <template v-slot:top>
        <v-row align="center" style="cursor: normal">
          <v-toolbar flat class="grey lighten-3">
            <v-toolbar-title>Promotion Items</v-toolbar-title>
            <v-col cols="3">
              <v-select
                label="Responsible Party"
                :items="validParties"
                item-text="name"
                item-value="id"
                v-model="responsiblePartyId"
                background-color="#fff"
                dense
                hide-details
                outlined>
              </v-select>
            </v-col>
            <v-spacer />
            <v-col
              cols="4"
              style="height:64px">
              <v-text-field
                v-model="search"
                placeholder="Search Promotion Items"
                background-color="#fff"
                prepend-inner-icon="mdi-magnify"
                append-icon="mdi-window-close"
                @click:append="search = ''"
                autocomplete="off"
                hide-details
                outlined
                dense>
              </v-text-field>
            </v-col>
          </v-toolbar>
        </v-row>
        <v-divider />
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import Es from '../axios/es'
import { buildElasticQuery } from '@/mixins/es_querybuilder'
import { fullWidthTable } from '@/mixins/table'
import { getValidParties } from '@/mixins/valid-parties'
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'
export default {
  data () {
    return {
      from: 0,
      loading: false,
      promoItems: [],
      search: '',
      headerProps: {
        filterable: true,
        sortable: false,
        class: 'accent white--text text-no-wrap'
      },
      baseHeaders: [
        {},
        { text: 'Source', value: 'source_name' },
        { text: 'Item Id', value: 'item_id' },
        { text: 'Name', value: 'item.description' },
        { text: 'UPC', value: 'item.upc' },
        { text: 'Vendor Id', value: 'item.vendor_id' },
        { text: 'Vendor Name', value: 'item.vendor_name' },
        { text: 'Retail Price',  value: 'item.sale_price' },
        { text: 'Cost', value: 'item.cost' },
        { text: 'Pack Size', value: 'item.pack_size' },
        { text: 'Promotion Number', value: 'promo.promo_number' }
      ],
      responsiblePartyId: ''
    }
  },
  name: 'PromoItems',
  mixins: [buildElasticQuery, fullWidthTable, getValidParties, displayAlert, utils],
  watch: {
    responsible_parties: {
      handler (newValue) {
        if (newValue?.length > 0) {
          this.setValidParties(newValue)
        }
      },
      deep: true
    },
    validParties: {
      handler (newValue) {
        if (newValue?.length > 0) {
          const partyId = sessionStorage.getItem('responsiblePartyId')
          if (partyId) {
            const selectedParty = newValue.find(p => p?.id === partyId)
            if (selectedParty) {
              return this.responsiblePartyId = partyId
            }
          }
          this.responsiblePartyId = newValue[0].id
        }
      },
      deep: true
    },
    responsiblePartyId: {
      handler (newValue) {
        if (newValue) {
          sessionStorage.setItem('responsiblePartyId', newValue)
          this.getPromoItems()
        }
      }
    }
  },
  beforeCreate () {
    this.$store.dispatch('getPromoKeyValues')
  },
  async created () {
    this.resetAlert()
    this.nonTableHeight = 225
    if (this.responsible_parties.length > 0) {
      await this.setValidParties(this.responsible_parties, true)
    }
    if (this.$route.meta.referrer === 'Promo') {
      const persistedSearch = sessionStorage.getItem('ipro_promo_PromoItems')
      if (persistedSearch) {
        this.search = persistedSearch
      }
    }
    this.tableChanged = this.tableChange(this.getPromoItems)
    this.submitSearch = this.submitSearch(this.getPromoItems)
  },
  computed: {
    responsible_parties () {
      return this.$store.getters.responsible_parties
    },
    headers () {
      return this.baseHeaders.map(header => {
        return { ...this.headerProps, ...header }
      })
    },
    itemTenantKey () {
      return this.getTenantKey('ITEM')
    }
  },
  methods: {
    async getPromoItems () {
      this.loading = true
      const query = this.buildElasticQuery('PROMO')
      query.bool.filter.push({
        term: { 'promo.responsible_party_id.keyword': this.responsiblePartyId }
      })
      const payload = {
        from: this.from,
        size: this.pageSize,
        query
      }
      try {
        let items = []
        const res = await Es.post('PROMO_ITEM', '/_search?rest_total_hits_as_int', payload)
        const { hits, total } = res.hits
        if (hits?.length > 0) {
          const { fulfilled, rejected } = await this.getAllSettled(hits.map(result => {
            return this.getItemData(result._source)
          }), true)
          if (rejected.length > 0) throw rejected
          items = fulfilled
        }
        this.serverItemsLength = total
        this.promoItems = items
      } catch (err) {
        this.handleError(err)    
      } finally {
        this.loading = false
      }
    },
    async getItemData (promoItem) {
      const payload = this.buildItemPayload(promoItem)
      const res = await Es.post('ITEM', '/_search?rest_total_hits_as_int', payload)
      if (res?.hits?.hits?.length > 0) {
        const result = res.hits.hits[0]
        promoItem.source_name = this.$store.getters.getSourceName(promoItem.source)
        promoItem.item = this.formatItemPrices(result._source)
      }
      return promoItem
    },
    viewPromo (item) {
      const promo_category = item.promo.promo_category
      this.$router.push({
        name: 'Promo',
        query: { id: item.promo.id },
        params: { promo: item.promo, promo_category }
      })
    },
    buildItemPayload (promoItem) {
      const payload = {
        query: {
          bool: {
            filter: [
              { term: { [`${this.itemTenantKey}`]: this.$auth.tenant } },
              { term: { 'source.keyword': promoItem.source } },
              { term: { 'item_id.keyword': promoItem.item_id } }
            ]
          }
        }
      }
      return payload
    }
  }
}
</script>